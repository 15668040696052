
const ScreenLoader = (props: any) => {

  console.log("screenloader test",props.color)

  return (
    <div className={`w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-50 bg-${props.color} `}>
      <svg className="animate-spin" width="70" height="70" viewBox="0 0 22 22" fill="none">
        <path d="M21.896 11C21.896 17.0177 17.0177 21.896 11 21.896C4.9823 21.896 0.104004 17.0177 0.104004 11C0.104004 4.9823 4.9823 0.104004 11 0.104004C17.0177 0.104004 21.896 4.9823 21.896 11ZM2.29842 11C2.29842 15.8057 6.19424 19.7016 11 19.7016C15.8057 19.7016 19.7016 15.8057 19.7016 11C19.7016 6.19424 15.8057 2.29842 11 2.29842C6.19424 2.29842 2.29842 6.19424 2.29842 11Z" fill="url(#paint0_linear_2993_206634)" />
        <defs>
          <linearGradient id="paint0_linear_2993_206634" x1="8.03545" y1="1.24821" x2="26.5768" y2="13.5484" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B3B3B3" />
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default ScreenLoader