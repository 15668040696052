const transalation = {
  Search: "Recherche",
  Cancel: "Annuler",
  Schedule: "Calendrier",
  StartNow: "Commencez maintenant",
  Online: "En ligne",
  Busy: "Occupé",
  Logout: "Se déconnecter",
  NoResultsFound: "Aucun résultat trouvé",
  Yes: "Oui",
  No: "Non",
  OopsSomethingWentWrong: "Oups, quelque chose s'est mal passé",
  Deny: "Refuser",
  Join: "Rejoindre",
  MeetingID: "Carte d'identité",
  JoinMeeting: "Rejoindre la réunion",
  Calls: "Appel",
  Create: "Créer",
  Invite: "Inviter",
  Add: "Ajouter",
  Remove: "Retirer",
  Edit: "Modifier",
  Delete: "Supprimer",
  Share: "Partager",
  Date: "date",
  Time: "Temps",
  Set: "Ensemble",
  SignUp: "S'inscrire",
  Page: "page",
  SignIn: "Se connecter",
  Password: "Mot de passe",
  LogOut: "Se déconnecter",
  Start: "Commencer",
  Allow: "Permettre",
  Reject: "Rejeter",
  Accept: "Accepter",
  NetworkError: "Assurez-vous d'être connecté à un réseau",
  with: "avec",
  by: "par",
  ScreenSharedBy: "Écran partagé par",
  Dashboard: {
    TodaysMeeting: "Le rendez-vous d'aujourd'hui",
    UserIsLoggedout: " L'utilisateur est déconnecté",
    OK: "D'accord",
    StartaMeeting: "Commencer",
    EnterMeetingID: "l'ID de la réunion",
    EnterPassword: "le mot de passe",
    Join: "Rejoindre",
    SafariMsg:
      "Les paramètres de votre navigateur bloquent la fenêtre de réunion. Veuillez activer la fenêtre contextuelle à partir des paramètres de safari à afficher.",
    LogoutMsg: "Êtes-vous sûr de déconnecter Hoolva?",
    AllMeetings: "Toutes les réunions",
    ListAll: "Tout lister",
    MeetinginProgress: "Réunion en cours",
    OpenMeetings: "Réunions ouvertes",
    Today: "Aujourd'hui",
    Yesterday: "Hier",
    Tomorrow: "Demain",
    Invite: "Inviter",
    Copied: "Copié",
    Copy: "Copie",
    StartsIn: "Commence dans",
    Minutes: "minutes",
    YouHaveNoUpcomingMeetings: "Vous n'avez pas de réunions à venir ...",
    ScheduleMeeting: "Réunion d'horaire",
    EditMsg: "Êtes-vous sûr de vouloir modifier cette réunion?",
    EditSingleOccurrence: "Modifier une seule occurrence",
    EditAllOccurrence: " Modifier tous les événements",
    InvitePeople: "Inviter des gens",
    EmailPlaceholder: "E-mail (e-mail de l'institut préféré)",
    AddEmail: "Ajouter un e-mail",
    EmailSentSuccsessfully: "E-mail envoyé avec succès",
    MeetingDeleteMsg: "La réunion est supprimée avec succès",
    MeetingNotDeleteMsg: "La réunion n'est pas supprimée",
    DeleteMsg: " Êtes-vous sûr de vouloir supprimer cette réunion?",
    DeleteSingleOccurrence: " Supprimer une seule occurrence",
    DeleteAllOccurrence: "Supprimer tous les événements",
    Calendar: "Calendrier",
    TopicError: "Le champ de sujet ne peut pas être vide",
    TopicMaxLenError: "Limite de longueur du sujet dépassé",
    DiscriptionError: "Description Limite de longueur dépassée",
    TopicMinLenError: "La longueur du sujet doit être supérieure à deux cents.",
    TopicNumError: "Le sujet ne doit pas contenir de nombres",
    TopicBlankError: "Le champ de sujet ne peut pas être vide",
    TimeError: "Ne pas entrer passé et présent",
    OccurenceError: "N'entrez pas moins de deux",
    PleaseMarkDays: "Veuillez marquer les jours",
    MeetingEditedMsg: "La réunion est édité avec succès",
    MeetingNotEditedMsg: "La réunion n'est pas modifiée",
    MeetingCreatedMsg: "La réunion est créée avec succès",
    MeetingNotCreatedMsg: "La réunion n'est pas créée",
    Topic: "Sujette *",
    MyMeeting: "Mon rendez-vous",
    Description: "description",
    EnterDescription: "Entrez description",
    When: " Quand *",
    OnStart: "Au départ",
    RepeatEvery: "Répéter chaque",
    Recurring: "Récurrente",
    Day: "Jour",
    Week: "Semaine",
    Month: "Mois",
    OccursOn: " Se produit",
    Ofthemonth: "du mois",
    EndDate: "Date de fin",
    After: "Après",
    Occurrences: "occurrences",
    History: "Histoire",
    RecurringMeeting: "Récurrente",
    HoolvaRoot: "Racine de hoolva",
    ProfilePicture: "Image de profil",
    Replace: "Remplacer",
    Upload: "Télécharger",
    Settings: "Paramètres",
    General: "Générale",
    Profile: "Profil",
    AudioVideo: "l'audio & Vidéo",
    Notification: "Notification",
    Themes: "Thèmes",
    About: "À propos",
    Security: "Sécurité",
    NotificationPopup : "Fenêtre contextuelle de notification",
    NotificationSound : "Son de notification",
  },
  Chat: {
    PinnedBy: "Épinglé par",
    Pin: "Épingle",
    UnPin: "Détacher",
    Recent: "Récente",
    Searched: "Cherché",
    IsCalling: "appelle",
    Messages: "messages",
    NewChat: "Nouveau chat",
    Groups: "Groupes",
    StartConversation: "Commencer la conversation",
    CreateGroup: "Créer un groupe",
    GroupName: "Nom de groupe",
    Description: "description",
    SearchMembers: "Membres de la recherche",
    GrpNameErrorMsg:
      "Le caractère maximum autorisé pour le nom du groupe est de 50.",
    InviteUser: "Inviter l'utilisateur",
    SearchUsers: "Rechercher les utilisateurs",
    AddMembers: "Ajouter des membres",
    SearchMembersToAdd: "Les membres de la recherche à ajouter",
    Admin: "Administrer",
    Inactive: "inactive",
    RemoveAsAdmin: "Supprimer comme administrateur",
    AddAsAdmin: "Ajouter comme administrateur",
    ArchiveGroup: "Archive Group",
    LeaveGroup: " Quitter le groupe",
    LastSeen: "Dernier vu",
    HoursAgo: "il y a des heures",
    Ago: "il y a",
    LastSeenAFewSecondsAgo:
      "A vu pour la dernière fois il y a quelques secondes",
    NewMessages: "Nouveaux messages",
    Loading: "Chargement",
    StartAConversation: "Commencer une conversation @",
    YouWereInACall: "Vous étiez en appel pour",
    YouHaveMissedCall: "Vous avez manqué un appel Depuis",
    YourCallWasNotAnswered: "Votre appel n'a pas été répondu",
    UnreadMessages: "Messages non lus",
    LimitedToMaximum: "Limité au maximum",
    Characters: "personnages",
    TextYouAreTryingToPasteIs: "Texte que vous essayez de coller est",
    CharactersMoreThanExpectedLimit: "caractères plus que prévu",
    FileMaxSizeError: "La taille maximale du fichier doit être de 100 Mo.",
    CannotPasteAFolder: "Ne peut pas coller un dossier.",
    IsTyping: "est en train d'écrire",
    Message: "message",
    UploadFailed: "Le téléchargement a échoué",
    DragRelease: "Traîner et libérer",
    Reply: "Répondre",
    ShareMessage: "Partager un message",
    ThisMessageHasBeenDeleted: "Ce message a été supprimé",
    You: "Toi",
    DeleteMessage: "Supprimer le message",
    DeleteConfirmation: "Etes-vous sur de vouloir supprimer ce message?",
    Video: "Vidéo",
    Update: "Mise à jour",
    In10minutes: " Dans 10 minutes",
    LaterToday: "Plus tard aujourd'hui",
    TomorrowMorning: "Demain matin",
    NextWeek: "La semaine prochaine",
    NextMonth: "Le mois prochain",
    Custom: "Coutume",
    SearchResultFor: "Résultat de la recherche pour",
    Members: "Membres",
    Direct: "Messages directs",
    AllMessages: "Tous les messages",
  },
  Call: {
    CallPopupMsg: "actuellement ouvert dans la fenêtre pop-up",
    CallInfo: "callinfo",
    ScreenSharePausedBy: "Partage d'écran a été interrompu par",
    AwaitingMembersToJoin: "En attente de membres pour rejoindre",
    Awaiting: "En attente",
    ToJoin: "joindre",
    Disconnect: "Se déconnecter",
    HostMsg: "Votre hôte de réunion a mis fin à la réunion",
    CallingYou: "Je t'appelle...",
    AlreadyInCall: "Vous êtes déjà en appel avec",
    AcceptPrompt: "Accepter cet appel mettrait fin à votre appel existant",
    IncomingCallFrom: "Appel entrant de ",
    BrowerNotSupport: "Votre navigateur ne supporte pas l'élément audio.",
  },
  Notifications: {
    JoinTheMeeting: "demandé à rejoindre la réunion",
    NewMsg: "Nouveau message reçu.",
    ToEveryone: "à tout le monde.",
    SharedAnImage: "partagé une image",
    SharedAFile: "partager un fichier",
    RaisedHand: "main levée.",
    NotSupporting:
      "Ce navigateur ne prend pas en charge la notification de bureau",
    NewMsgFrom: "Vous avez un nouveau message de",
    NewMsgIn: "Nouveau message dans",
    Notifications: "Notifications",
    Notify: "Notifier",
    DirectMsg: "Messages directs",
    Dnd: "Ne pas déranger",
    allowNotification: "Autoriser les notifications",
    allMsg: "Tout nouveau message",
    from: "Depuis",
    to: "À",
    enableNotification: "Activer la notification spécifique",
    Groups: "Groupes",
    Select: "Sélectionner",
    previewMsg: "Aperçu de tous les messages",
    MuteNotification: "Coupez tous les sons de cette application",
    IncomingChat: "Son du chat entrant",
    OutgoingChat: "Son du chat sortant",
    IncomingGroup: "Son de discussion de groupe entrant",
    IncomingCall: "Son d'appel entrant",
    exclude:"Exclude"
  },
  Dial: {
    ChooseMsg: "Choisissez votre connectivité audio",
    ComputerAudio: "Audio informatique",
    DialIn: "Composer",
    DialOut: "Composer",
    PassCode: "Code de réussite",
    DialByYourLocation: "Composez par votre emplacement",
    Microphone: "microphone",
    Speaker: "Conférencière",
    SwitchAudio: "Commutateur audio",
    return: "retour",
    JoinMeeting: "Rejoindre la réunion",
  },
  Meeting: {
    AudioIssues: "Problèmes audio",
    IHeardEcho: "J'ai entendu écho",
    ICouldntHearOneParticipant: "Je ne pouvais pas entendre un participant",
    VideoIssues: "Problèmes vidéo",
    VideoWasFrozen: "La vidéo était gelée",
    Flickering: "La vidéo des participants vacillait",
    AccountVerification: "Vérification de compte",
    EmailText:
      "Nous avons envoyé un e-mail à tom@xbox.com.Veuillez vérifier votre compte via ce lien par e-mail.",
    WrongEmail: "Mauvaise adresse mail?Revenir à",
    ResendEmail: "Ré-envoyer l'email",
    UsernameOr: "Nom d'utilisateur / code de réunion",
    ForgotPassword: "Mot de passe oublié?",
    LoginWith: "Connectez-vous avec",
    LoginError: "Nom d'utilisateur ou mot de passe non valide",
    WelcomeBack: "Content de te revoir",
    WelcomeMsg:
      "Vous n'êtes qu'à quelques clics d'une excellente expérience de rencontre.Collaborer et travailler de n'importe où.",
    DontHaveAnAccount: "Vous n'avez pas de compte?",
    FirstName: "Prénom",
    LastName: "Nom de famille",
    CreatePassword: "Créer un mot de passe",
    Email: "E-mail (e-mail de l'institut préféré)",
    ByClicking: "En cliquant sur l'inscription, vous acceptez le hoolva",
    Terms: "Termes",
    And: "Et",
    PrivacyPolicy: "politique de confidentialité",
    CreateAnAccount: "Créer un compte",
    HoolvaMsg:
      "est votre partenaire dans les réunions distantes.Vous pouvez passer des appels vidéo et audio, des participants aux messages, prendre des notes, attribuer des tâches et planifier des réunions.",
    AlreadyAMember: "Déjà membre?",
    StartAMeeting: "Commencer une réunion",
    BrandMsg:
      "est un outil facile à appeler, des idées d'échange de chat au sein de votre équipe et à l'extérieur",
    NewMeeting: "Nouvelle réunion",
    InvalidPassword: "passe de réunion non valide",
    InvalidID: "ID de réunion non valide",
    NetworkError: "Assurez-vous que vous êtes connecté à un réseau.",
    EnterMeetingID: "Entrez l'ID de la réunion",
    ClearHistory: "Histoire claire",
    EnterPassword: "Entrer le mot de passe",
    YourVideoIsOff: "Votre vidéo est désactivée",
    TestMic: "MIC à tester",
    Playing: "jouant",
    YourDisplayName: "Votre nom d'affichage",
    EnterEmail: "Entrez votre identifiant de messagerie (facultatif)",
    AllowMic: "Autorisez votre micro et votre vidéo",
    PermissionText:
      "Hoolva veut accéder à votre micro et à votre appareil photo pour continuer la réunion.Sélectionnez Autoriser à donner l'autorisation.",
    BreakoutRoom: "Salle d'évasion",
    Chat: "Chatte",
    Members: "Membres",
    Mic: "Micro",
    More: "Plus",
    Reactions: "Réactions",
    ShareNow: "Partage maintenant",
    ScreenshareResumeStop: "Capture d'écran CV / arrêt",
    ScreensharePauseStop: "Pause d'écran / arrêt",
    Video: "Vidéo",
    WhiteBoard: "Planche blanche",
    LongMsgAlert: "Vous devrez raccourcir votre message pour l'envoyer.",
    AllChat: "Tout chat",
    You: "Toi",
    ChooseMembers: "Choisir les membres",
    All: "Toute",
    Everyone: "Tout le monde",
    Room: "Chambre",
    RecentChats: "Chats récents",
    Host: "Hôtesse",
    MailNotSend: "Quelque chose a mal tourné, le courrier ne l'envoie pas.",
    InviteMailSendSuccessfully: "Invitez le courrier Envoyer avec succès.",
    InvitePeople: "Inviter des gens",
    Copied: "Copié",
    CopyMeetingURL: "Copier URL de la réunion",
    MembersWaitingToJoin: "Les membres attendant de rejoindre",
    AllowAll: "Autorise tout",
    Settings: "Paramètres",
    General: "Générale",
    SortBy: "Trier par",
    Participant: "participant (aZ)",
    MeetingjoinTime: "Réunion Rejoignez le temps",
    ParticipantsUnmuted: " Participants (sans victime)",
    ParticipantsVideoOn: "Participants (vidéo sur)",
    RemoveMsg: "Voulez-vous retirer cette personne de la réunion?",
    MuteAll: "Rendre tout silencieux",
    Record: "Enregistrer",
    StoreRecord: "Où voulez-vous stocker cet enregistrement?",
    HoolvaCloud: "Cloud hoolva",
    StopRecording: "Voulez-vous arrêter l'enregistrement?",
    Stop: "Arrêt",
    StopRecord: "Enregistrer",
    ConnectByPhone: "Connectez-vous par téléphone",
    ShowVideoTile: "Afficher la vidéotile",
    HideVideoTile: "Masquer la vidéotile",
    FullScreen: "Plein écran",
    ExitFullScreen: "Quitter le mode plein écran",
    ScreenSharePausedBy: "Partage d'écran pause par",
    ScreenShare: "Partage d'écran",
    EndForMe: "Fin pour moi",
    EndForAll: "Fin pour tous",
    MeetingID: "Carte d'identité",
    Password: "Mot de passe",
    Pin: "Épingle",
    MeetingInfo: "Informations sur la réunion",
    Resume: "CV",
    Rec: "Récolte",
    StopRec: "Stop Rec",
    YourScreenIsBeingShared: "Votre écran est partagé",
    Locked: "Fermé à clé",
    DropFileHere: "Déposez le fichier ici",
    Or: "ou",
    SelectFile: "Choisir le dossier",
    Uploading: "Téléchargement",
    AllowMembersToDownload: "Permettre aux membres de télécharger",
    AllowMembersToNavigate: "Permettez aux membres de naviguer",
    RestrictAccess: "Accès restreint",
    StopShare: "Stop Partage",
    WhiteboardIsInProgress: "Le tableau blanc est en cours",
    CloseWhiteBoard: "Fermer la planche blanche",
    OpenWhiteBoard: "Planche blanche ouverte",
    AudioVideo: "Audio & Video",
    Camera: "Caméra",
    Microphone: "microphone",
    TestSpeaker: "Test du haut-parleur",
    Language: "Langue",
    TopAndBottomBar: "Barre supérieure et inférieure",
    SwitchToOneSide: "Passer de côté",
    Profile: "profil",
    DisplayName: "Afficher un nom",
    Update: "Mise à jour",
    YouRemovedFromMeeting: "Vous avez été retiré de la réunion.",
    HostMutedYourMicrophone: "L'hôte a coupé votre microphone.",
    HostRequestingToUnmute: "L'hôte demande à réactiver votre microphone",
    HostTurnedoffCamera: "L'hôte a éteint votre appareil photo.",
    HostRequestingToStartCamera:
      "L'hôte demande de démarrer votre appareil photo.",
    LostConnectionWithServer:
      "Connexion perdue avec le serveur.Essayer de se reconnecter.",
    SomethingWrongUsingMediaDevices:
      "Quelque chose s'est mal passé en utilisant des appareils multimédias",
    LostConnectionWithServerPleaseJoinAgain:
      "Connexion perdue au serveur.Veuillez vous joindre à nouveau.",
    WentWrongCameraPleaseCheckYourSettings:
      "Quelque chose a mal tourné avec votre appareil photo.Veuillez vérifier vos paramètres.",
    OopsOur: "Oops!Notre",
    ConnectionIsHavingIssues: "La connexion a des problèmes de notre côté.",
    MaximumAllowedParticipantsLimitHasReached:
      "La limite maximale des participants autorisée a atteint.",
    MeetingParticipantLimitReached:
      "La limite des participants de la réunion a atteint cette réunion.",
    AccountLimitReached:
      "Le nombre maximum de réunions simultanées autorisées pour le compte a été atteinte.Veuillez contacter votre administrateur.",
    PleaseMakeSureConnectedToInternet:
      "Veuillez vous assurer que vous êtes connecté à Internet.",
    IdentifiedSomeProblems:
      "Nous avons identifié certains problèmes avec votre connexion.Veuillez vérifier votre Internet ou utiliser un autre réseau",
    AttemptingToReconnect: "Tentative de reconnexion",
    ThreeMinutesLeft:
      "Il ne vous reste que trois minutes lors de cette réunion.",
    MaximumAllowedDurationReached:
      "Votre réunion s'est terminée car la limite de durée de réunion maximale autorisée est atteinte.",
    MeetingEnded: "La réunion s'est terminée",
    ThankYouForUsing: "Merci d'utiliser",
    Recording: "Enregistrement",
    TheHostHasEndedTheMeeting: "L'hôte a mis fin à la réunion",
    NotAllowed: "Vous n'êtes pas autorisé à rejoindre cette réunion",
    PleaseWait: "S'il vous plaît, attendez",
    HostWillLetYouSoonIn: "Votre hôte vous laissera bientôt entrer.",
    HostSoonStart: "Votre hôte sera bientôt là pour commencer la réunion.",
    Joining: "Joindre",
    LeaveMeeting: "Quitter la réunion",
    IfUAre: "Si vous êtes hébergé, s'il vous plaît",
    ToStart: "Pour commencer la réunion",
  },
};
export default transalation;