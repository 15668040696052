import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../store';
import { MeetingSession } from "hdmeet"
import ScreenLoader from '../atom/ScreenLoader/screenLoader';
const AuthKeyCloak = (props: any) => {
    const { keycloak } = useKeycloak();
    const keyCloakToken = useSelector((state: any) => state.Main.keyCloakToken)

    const dispatch = useDispatch();
    const apiKey = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';
    const logLevel = 1;
    // const environmentLevel = 'sandbox' //us7
    // const environmentLevel = 'loadtest' //us4
    //  const environmentLevel = 'development' //us1
    //const environmentLevel = 'chatclient' //206
    //const environmentLevel = 'testing' //us3
    const environmentLevel = 'production' //us2
    // const environmentLevel = 'betadevelop' //us9
    // const environmentLevel = 'test' //
    // const environmentLevel = 'BETASANDBOX' //
    // const environmentLevel = 'codetest' //us6 
    // const environmentLevel = 'testkubernetes' //new us4 clusteer
    // const environmentLevel = 'STAGING-1' //“https://us2-cluster.hoolva.com”,
    // const environmentLevel = 'STAGING-2' //“https://us4-cluster.hoolva.com”,


    const meetingSession = useSelector((state: any) => state.Main.meetingSession)

    const user = useMemo(() => new MeetingSession(apiKey, logLevel, environmentLevel), []);
    dispatch(actionCreators.setMeetingSession(user));
    dispatch(actionCreators.setMeetingEnviornemnt(environmentLevel))


    useEffect(() => {
        console.log("Token Updated")
        if (keycloak.token !== '') {
            console.log("Token Updated")
            if (meetingSession) {
                meetingSession.AddApiHeader({
                    token: keycloak.token,
                    optional: [{
                        header: 'X-Auth-Token',
                        value: keycloak.token
                    }]
                })
            }
        }
    }, [keycloak, keyCloakToken]);

    useEffect(() => {

        if (props.ready) {
            dispatch(actionCreators.setOnReady(true))
        }
    }, [props.ready])



    useEffect(() => {
        if (props.userIsloggedOut) {
            dispatch(actionCreators.setKeycloackLoggedInState(false))
        }

    }, [props.userIsloggedOut])
    useEffect(() => {
        dispatch(actionCreators.setKeycloakToken(props.token))
    }, [props.token])
    return <div>
        {props.ready ? props.children : <ScreenLoader />}
    </div>
}
export default AuthKeyCloak;

