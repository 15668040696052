import Keycloak from 'keycloak-js';
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
function getSubDomain() {
	const host = window.location.hostname;
	// console.log("subdomain",host)
	// const host = 'rahul.hoolva.com/app';
	// const host = 'hoolva.com/app';
	// const host = 'rahul.us3-server-node0.hoolva.com';
	let real = 'system'
	let sub = host.split('.')
	if (sub.length > 2) {
		real = sub[0];
	}
	return real;
}
const realm = getSubDomain()
console.log("realm",realm)
const keycloak = new Keycloak({
	// url: 'https://us2-cluster.hoolva.com/auth/',
	// realm: 'Hoolva',
	// realm: 'Hoolva',
	// url: 'https://us3-test.hoolva.com/auth/',
	url: 'https://us6-cluster.hoolva.com/auth/',
	realm: realm,
	// url: 'https://us4-cluster.hoolva.com/auth/',
	// realm: 'System_Tenant',
	// url: 'https://us3-server-node0.hoolva.com/sso/'
	clientId: `${realm}_web`
});

export default keycloak;
