import { BrowserRouter } from "react-router-dom";
import './App.css';
import { Provider, useDispatch } from 'react-redux';
import { actionCreators, store } from "./store";
import { ThemeProvider } from './theme/themeContext';
import { AuthProvider } from './navigation/auth/useAuth';
import RouterConfig from './navigation/router.config';
import { AppProvider } from './appContext';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keyCloak from './auth/keycloak';
import AuthKeyCloak from './auth/authKeyCloak';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { useEffect, useState } from "react";
import PageVisibility from '../src/containers/PageVisibility/pageVisibility'

import QueryString from "querystring";
import axios from "axios";
import ChatSettings from "./layout/layout1/components/settings/chatSettings";

function App() {
  const [token, setToken] = useState({})
  const [ready, setReady] = useState(false)
  const [location, setLoaction] = useState<any>('')
  const [userIsloggedOut, setUserLoggedOut] = useState<boolean>(false)
  const [mode, setMode] = useState('')
  const [realmExist, setRealmExist] = useState(true)
  const [isMApp, setIsMApp] = useState(false)
  const [branding, setBranding] = useState({})
  function getSubDomain() {
    const host = window.location.hostname;
    // console.log("subdomain",host)
    // const host = 'rahul.hoolva.com/app';
    // const host = 'hoolva.com/app';
    // const host = 'rahul.us3-server-node0.hoolva.com';
    let real = 'system'
    let sub = host.split('.')
    if (sub.length > 2) {
      real = sub[0];
    }
    return real;
  }
  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const checkRealm = async (domain: any) => {
    console.log("subdomain2223333",domain)
    // https://us1-dev.hoolva.com
    // "https://us3-test.hoolva.com/auth"
    //us6-cluster.hoolva.com/v1/portalGetTenant`
    https: try {
      await axios
        .post(`https://us6-cluster.hoolva.com/v1/portalGetTenant`, {
          name: domain,
        })
        .then((result) => {
          const con = {
            url: "https://us6-cluster.hoolva.com/auth",
            realm: domain,	// url: 'https://us4-cluster.hoolva.com/auth/',

            clientId: `${domain}_web`,
          }
          setLoaction(con)
        })
    } catch (error) {
      // setRealm('realmNotExist')
      console.error("FAILED")
    }
  }

  // console.warn = console.log = console.error = () => { };

  useEffect(() => {
    const queryStrings = window.location.search;
    const domain = getSubDomain()
    document.title = `${capitalizeFirstLetter(domain)} | Hoolva`;
    const params: any = QueryString.parse(queryStrings);
    // const qwerty: string = params['?qwerty'];
    setMode(params['q']);
    setIsMApp(params['d'] === 'm' ? true : false);
    checkRealm(domain)


  }, [])

  useEffect(() => {
       const domain = getSubDomain()
       if (domain) {
         axios
           .post(
             "https://us6-cluster.hoolva.com/v1/getTenantInfo",
             {
               sname: domain,
             },
             {
               headers: {
                 "hoolva-app": "b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5",
               },
             }
           )
           .then((res) => {
             setBranding(res)
             //  LocalDb.get(deviceDB, "UserData", "theme", (data: any) => {
             //    if (data == "dark") {
             //      dispatch(actionCreators.setThemePalette(res.data.theme.dark))
             //      dispatch(actionCreators.setTheme("dark"))
             //    } else {
             //      dispatch(actionCreators.setThemePalette(res.data.theme.lite))
             //      dispatch(actionCreators.setTheme("light"))
             //    }
             //  })
             var link = document.createElement("link")
             link.type = "image/x-icon"
             link.rel = "icon"
             link.href = res.data.logos.favicon
             document.head.appendChild(link)
           })
       }
     }, [])

  const eventLogger = (event: any, error: any) => {
    console.log('onKeycloakEvent', event, error);
    if (event === "onAuthLogout") {
      setUserLoggedOut(true)
    }
    if (event === "onReady") {

      setReady(true)
    }


  };

  const tokenLogger = (tokens: any) => {
    setToken(tokens)
    console.log('onKeycloakTokens', tokens);
  };
  return (
    <div className="App">
      <Provider store={store}>
        <ReactKeycloakProvider
          authClient={keyCloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
        >
          <AuthKeyCloak
            token={token}
            userIsloggedOut={userIsloggedOut}
            ready={ready}
          >
            <ReactNotifications />
            <AppProvider>
              <AuthProvider>
                <ThemeProvider branding={branding}>
                  <PageVisibility>
                    <ChatSettings>
                      <BrowserRouter>
                        <RouterConfig />
                      </BrowserRouter>
                    </ChatSettings>
                  </PageVisibility>
                </ThemeProvider>
              </AuthProvider>
            </AppProvider>
          </AuthKeyCloak>
        </ReactKeycloakProvider>
      </Provider>
    </div>
  )
}

export default App;
